<template>
  <loader />
  <!-- loader END -->
  <DefaultSidebar @closeSidebar="sidebarStatus=!sidebarStatus" />
  <DefaultHeader :image="logoimage" v-show="!isRecommendPage" :closeSidebar="sidebarStatus" />
  <!-- <DefaultRightSidebar /> -->
  <!-- Wrapper Start -->
  <div class="wrapper">
    <!-- <template v-for="(option, index) in breadcrumboptions" :key="index">
      <breadcrumb :img="option.img" :title="option.title" v-show="this.$route.meta.name === option.title" />
    </template> -->
    <div id="content-page" class="content-page" :class="{ 'recommend-style': isRecommendPage }">
      <div class="container" title="root" :class="{ 'recommend-style': isRecommendPage, 'index_container': $route.name === 'social.list' }">
        <!-- 新增貼文 -->
        <AddSocialPost :showForm="false"></AddSocialPost>
        <div class="row" style="position: relative;">
          <DefaultSidebarPc></DefaultSidebarPc>
          <router-view v-slot="{ Component }">
            <!-- <transition name="fade"> -->
            <component :is="Component" />
            <!-- </transition> -->
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <!-- Wrapper End-->
  <DefaultFooter />
</template>
<script>
import DefaultSidebar from '../components/custom/partials/Sidebar/DefaultSidebar'
import DefaultSidebarPc from '../components/custom/partials/Sidebar/DefaultSidebarPc'

import DefaultHeader from '../components/custom/partials/Header/DefaultHeader'
import DefaultFooter from '../components/custom/partials/Footer/DefaultFooter'
// import DefaultSidebarPc from '../components/custom/partials/RightSidebar/DefaultRightSidebar'
import Breadcrumb from '../components/custom/Breadcrumb/Breadcrumb'
import logo from '../assets/images/logo.png'

export default {
  name: 'Default',
  components: {
    DefaultSidebar,
    DefaultHeader,
    DefaultFooter,
    // DefaultRightSidebar,
    Breadcrumb,
    DefaultSidebarPc
  },
  data() {
    return {
      breadcrumboptions: [
        {
          img: require('@/assets/images/page-img/profile-bg2.jpg'),
          title: 'Weather'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Group'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Group detail'
        },
        {
          img: require('@/assets/images/page-img/profile-bg4.jpg'),
          title: 'Birthday'
        },
        {
          img: require('@/assets/images/page-img/profile-bg5.jpg'),
          title: 'Profile Image'
        },
        {
          img: require('@/assets/images/page-img/profile-bg9.jpg'),
          title: 'Profile Video'
        },
        {
          img: require('@/assets/images/page-img/profile-bg3.jpg'),
          title: 'Friend List'
        },
        {
          img: require('@/assets/images/page-img/profile-bg6.jpg'),
          title: 'Profile Event'
        },
        {
          img: require('@/assets/images/page-img/profile-bg8.jpg'),
          title: 'Music'
        },
        {
          img: require('@/assets/images/page-img/profile-bg6.jpg'),
          title: 'Calendar'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Store Category'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Category List'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Store'
        },
        {
          img: require('@/assets/images/page-img/profile-bg7.jpg'),
          title: 'Store Checkout'
        }
      ],
      logoimage: logo,
      sidebarStatus: false
    }
  },
  mounted() {
    this.$store.dispatch('User/fetcChatUnReadNumtAction')
    setInterval(() => {
      if (
        this.$route.name === 'social.profilemain' ||
        this.$route.name === 'user.profile-edit'
      ) {
        // do nothing
      } else {
        this.$store.dispatch('User/fetcChatUnReadNumtAction')
        this.$store.dispatch('User/fetchUserAction')
      }
    }, 30000)
  },
  computed: {
    isRecommendPage() {
      return this.$route.name === 'socialRecommend.list'
    }
  }
}
</script>
<style>
/* 註解進入頁面淡入淡出 */
.fade-enter-active,
.fade-leave-active {
  /* transition: opacity 0.1s ease; */
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.recommend-style {
  max-width: 100%;
  width: 100%;
}

@media (min-width: 992px) {
  .container {
    max-width: 1350px;
  }
}

@media (max-width: 767px) {
  .index_container {
    padding: 0;
  }
}
</style>
